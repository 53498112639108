import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';

const Services = (props) => {
  // const services = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-services">
      <SEO title="事業内容" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>事業内容</h1>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container pb-6">
        <div className="row">
          {services.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                  </h2>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <div className="container pb-6">
        <div className="row">
          <div className="col-12 col-md-4 mb-4 bg-black">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>マイコンシステムのノイズ設計のアドバイスとコンサルティング</h2>
                <p className="mb-1">マイコンを使ったシステムに関するノイズ設計のアドバイスとコンサルティング</p>
                <p className="mb-1">マイコンデバイス設計に関するノイズ対策設計のアドバイス</p>
                <p className="mb-1">マイコンを使ったシステムに関する基盤設計のアドバイス</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-4 bg-black">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>IT農業、介護ヘルスケア関連のシステムコーディネート</h2>
                <p className="mb-1">関連システム構築に関するアドバイス、マーケティング、マネージメント</p>
                <p className="mb-1">関連システム構築に関する設計開発、受託開発</p>
                <p className="mb-1">関連システムおよび周辺機器の販売、流通</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-4 bg-black">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>主力センサ技術を用いた各種技術提案、システム提案、コンサルティング</h2>
                <p className="mb-1">生体センシング</p>
                <p className="mb-1">農業向けの栽培、育成、収穫、流通までの総合支援システム</p>
                <p className="mb-1">産業用途</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-4 bg-black">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>技術支援</h2>
                <p className="mb-1">マイコン及び周辺回路の技術支援</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-4 bg-black">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>各種電源技術ノウハウ、マーケティング実績、手法実績を用いた各種技術提案、システム提案、コンサルティング</h2>
                <p className="mb-1">最新設計手法を用いた設計手法提案</p>
                <p className="mb-1">各種ターゲット・トレンドのコンサルティング</p>
                <p className="mb-1">市場開拓支援</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          <div className="col-12 text-center">
            <img className="img-fluid" src="/page-data/services/jmind-service-img.png"/>
          </div>
        </div>
      </div>

      <div className="container pt-2 mb-4">
        <Call button />
      </div>

    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`;

export default Services;

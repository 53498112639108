import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const Call = props => (
  <div className="call">
    <div className="call-box-top">
      <div className="call-phone">
        <strong>株式会社 J-mind</strong>
      </div>
      <div className="call-email">
        <strong>Address: </strong>
        <span>〒{props.data.site.siteMetadata.contact.postcode}</span>
        <span> {props.data.site.siteMetadata.contact.address}</span>
      </div>
      <div className="call-email">
        <strong>Tel&Fax: </strong>
        <a href={`tel:${props.data.site.siteMetadata.contact.phone}`}>
        {props.data.site.siteMetadata.contact.phone}
        </a>
      </div>
      <div className="call-email">
        <strong>Email: </strong>
        <a href={`mailto:${props.data.site.siteMetadata.contact.email}`}>
          {props.data.site.siteMetadata.contact.email}
        </a>
      </div>
    </div>
    <div className="call-box-bottom">
    {props.button && (
        <a href="/contact" className="button mb-1">
          Contact
        </a>
    )}
      <a href="https://www.facebook.com/Jmind0725/" target="_blank"><img style={{width:30}} src="/page-data/icon-fb.svg" alt="facebookアイコン"/></a>
    </div>
    
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              postcode
              address
              email
              phone
            }
          }
        }
      }
    `}
    render={data => <Call button={props.button} data={data} />}
  />
);
